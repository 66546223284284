<template>
  <div class="page">
    <a-tabs default-active-key="1" v-model="current" @change="callback">
      <a-tab-pane key="1" tab="评价记录">
        <evaluate-record ref="record"></evaluate-record>
      </a-tab-pane>
      <a-tab-pane key="2" tab="评价审核" force-render>
        <evaluate-check ref="check"></evaluate-check>
      </a-tab-pane>
      <a-tab-pane key="3" tab="商品列表">
        <product-list @view="viewRecord"></product-list>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "goodsEvaluate",
  components: {
    evaluateRecord: () => import("./evaluateRecord"),
    productList: () => import("./productList"),
    EvaluateCheck: () => import("./evaluateCheck"),
  },
  data() {
    return {
      current: "1",
    };
  },
  mounted () {
    if (this.$route.query.current) {
      this.current = this.$route.query.current
    }
  },
  methods: {
    viewRecord(record) {
      this.current = "1";
    },
    callback(key) {
      if (key === "1") {
        this.$refs.record.initTable();
      }
      if (key === "2") {
        this.$refs.check.initTable();
      }
    },
  },
};
</script>

<style scoped>
</style>